import http from "@/axios/index"
import { URI } from "@/config/axios"

// 获取列表 /api/alarm/queryPageList{?deviceType,device,deviceStatus,pageNum,pageSize}
export const getList = (params = {}) => {
  return http({
    url: URI.system + 'api/alarmRule/queryPageList',
    method: 'get',
    params
  })
}

// 新增 /api/alarmRule/add
export const addData = (data = {}) => {
  return http({
    url: URI.system + 'api/alarmRule/add',
    method: 'post',
    data
  })
}

// 修改 /api/alarmRule/update
export const updateData = (data = {}) => {
  return http({
    url: URI.system + '/api/alarmRule/update',
    method: 'post',
    data
  })
}

// 删除 
export const delData = (id) => {
  return http({
    url: URI.system + 'api/alarmRule/delete',
    method: 'delete',
    params:{id}
  })
}

// 详情 api/alarmRule/queryById?id=
export const detailData = (id) => {
  return http({
    url: URI.system + 'api/alarmRule/queryById',
    method: 'get',
    params:{id}
  })
}

/* 子属性配置 */
// 新增 /api/alarmRule/addSonRule
export const addChild = (data = {}) => {
  return http({
    url: URI.system + 'api/alarmRule/addSonRule',
    method: 'post',
    data
  })
}

// 修改 /api/alarmRule/updateSonRule
export const updateChild = (data = {}) => {
  return http({
    url: URI.system + 'api/alarmRule/updateSonRule',
    method: 'post',
    data
  })
}

// 删除 /api/alarmRule/deleteSonRule{?id,sid}
export const delChild = (id,sid) => {
  return http({
    url: URI.system + 'api/alarmRule/deleteSonRule',
    method: 'delete',
    params:{id,sid}
  })
}

