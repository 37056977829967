<!-- 设备报警规则配置 -->
<template>
  <div class="device-alarm-rule-config">
    <!-- 面包屑 -->
    <els-bread class="_bread"></els-bread>
    <!-- header -->
    <div class="_header">
      <els-button-group type="primary" size="small" :column="buttons"></els-button-group>
      <div class="_search h--search">
        <els-form ref="searchRef" v-model="searchValue" :column="searchColumn" :elForm="{ inline: true, size: 'small' }"></els-form>
        <el-cascader :options="deviceStatusTree" filterable clearable size="small" ref="searchCascader"
          v-model="searchValue.deviceStatus" :props="{ label: 'name', children: 'childList', checkStrictly: true }"
          placeholder="设备状态" @change="searchStatusChange">
        </el-cascader>
        <el-button style="margin-left: 8px; border: none" size="small" icon="el-icon-search" type="primary"
          @click="handleSearch">搜索</el-button>
        <el-button style="margin-left: 8px; border: none" size="small" icon="el-icon-refresh"
          @click="handleReset">重置</el-button>
      </div>
    </div>
    <!-- content -->
    <div class="_content">
      <els-table ref="elsTableRef" show-expand :column="tableColumn" :onload="onload" menuWidth="240px" :isFixed="false">
        <template v-slot:expand="{ row }">
          <div class="_subtable-content">
            <els-table :data="row.sonRule ? reverseSonRule(row.sonRule) : []" :column="subTableColumn"
              :show-pagination="false" :isFixed="false" menuWidth="200px">
              <template v-slot:menu="{ row: subRow }">
                <els-button-group type="text" :params="{ row, subRow }" delimiter="divider"
                  :column="subTableMenus"></els-button-group>
              </template>
            </els-table>
          </div>
        </template>
        <template v-slot:menu="{ row }">
          <els-button-group type="text" :column="tableMenus" delimiter="divider" :params="row"></els-button-group>
        </template>
      </els-table>
    </div>
    <!-- dialog -->
    <els-form-dialog :visible.sync="dialogVisible" :title="dialogTitle" :loading="submitLoading" @submit="handleSubmit"
      width="50%">
      <els-form v-if="dialogVisible" ref="elsFormRef" class="_dialog-form" v-model="formData" :column="formColumn"
        :elForm="{ size: 'small' }" @change-deviceType="changeDeviceType" @change-alarmType="changeAlarmType">
        <template v-slot:deviceStatus>
                <el-cascader ref="formCascader" :options="deviceStatusTree" v-model="formData.deviceStatus" filterable
                        :props="{ label: 'name', children: 'childList', checkStrictly: true }"
                        @change="changeFormCascader">
                    </el-cascader>
                </template>
        </els-form>
    </els-form-dialog>
  </div>
</template>

<script>
//
import elsBread from "@/components/els-breadcrumb/main.vue";
import elsTable from "@/components/els-table/main.vue";
import elsDrawer from "@/components/els-drawer/main.vue";
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import elsButtonGroup from "@/components/els-button-group/main.vue";
//
// import mixinsDicts from "@/mixins/dict.js";
//
import { mapState } from "vuex";
import { pull } from "loadsh";
//
import {
  getList,
  addData,
  updateData,
  detailData,
  delData,
  addChild,
  updateChild,
  delChild,
} from "@/api/manager/alarmRuleConfig.js";
import { getDict } from "@/api/com/iot-dict.js";
import {
  getDeviceTypeData,
  getDeviceNames,
  getDeviceNodes,
} from "@/api/manager/device-status-rule.js";
import { getDataByDeviceType as getAlarmCodes } from "@/api/manager/alarmCode.js";
import { getStateList, getStateDict } from "@/api/manager/device-status-rule.js"
//
export default {
  // mixins: [mixinsDicts],
  components: {
    elsTable,
    elsDrawer,
    elsBread,
    elsFormDialog,
    elsForm,
    elsButtonGroup,
  },
  data: () => ({
    dicts: {
      ALARM_TYPE: [], //
      ALARM_GRADE: [], //
      DEVICE_TYPE: [], //
      DEVICES_STATUS: [], //
      ALARM_RULE_TYPE: [], //
      // NODE_ARG_CLASS: [],
      DEVICE_NAMES: [], //
      DEVICE_POINTS: [], //
      ALARM_CODE: [], //
      CONFIG_LEVEL: [
        { value: "1", label: "按设备类型" },
        { value: "2", label: "按设备" },
      ],
      ALARM_FLAG: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
    },
    deviceTypeCache: "",
    PAGE_NAME: "alarmRuleConfig",
    searchValue: {},
    drawerVisible: false,
    dialogVisible: false,
    importVisible: false,
    dialogTitle: "",
    formName: "add",
    formData: {},
    nodes: undefined,
    currentRow: undefined,
    submitLoading: false,
    deviceStatusTree: [],  // 设备状态树形结构
  }),
  // computed: {
  //   ...mapState(["breadMenus", "userInfo"]),
  //   buttons() {
  //     const add = { label: "新增告警规则", handle: this.handleAdd };
  //     const manage = { label: "告警码管理", handle: this.handleGoAlarmCode };
  //     return [add, manage];
  //   },
  //   searchColumn() {
  //     const dict = this.dicts;
  //     const deviceType = {
  //       prop: "deviceType",
  //       placeholder: "设备类型",
  //       tag: "el-select",
  //       cls: dict.DEVICE_TYPE,
  //       // required: true,
  //     };
  //     const deviceCode = {
  //       prop: "deviceNameOrCode",
  //       placeholder: "设备名称或编号",
  //       showWordLimit: true,
  //       // tag: "el-select",
  //       // cls: dict.DEVICE_NAMES,
  //     };

  //     return [deviceType, deviceCode];
  //   },
  //   data: () => ({
  //     dicts: {
  //       ALARM_TYPE: [], //
  //       ALARM_GRADE: [], //
  //       DEVICE_TYPE: [], //
  //       DEVICES_STATUS: [], //
  //       ALARM_RULE_TYPE: [], //
  //       // NODE_ARG_CLASS: [],
  //       DEVICE_NAMES: [], //
  //       DEVICE_POINTS: [], //
  //       ALARM_CODE: [], //
  //       CONFIG_LEVEL: [
  //         { value: "1", label: "按设备类型" },
  //         { value: "2", label: "按设备" },
  //       ],
  //       ALARM_FLAG: [
  //         { value: true, label: "是" },
  //         { value: false, label: "否" },
  //       ],
  //     },
  //     deviceTypeCache:"",
  //     PAGE_NAME: "alarmRuleConfig",
  //     searchValue: {},
  //     drawerVisible: false,
  //     dialogVisible: false,
  //     importVisible: false,
  //     dialogTitle: "",
  //     formName: "add",
  //     formData: {},
  //     nodes: undefined,
  //     currentRow: undefined,
  //     submitLoading: false,
  //   }),
    computed: {
      ...mapState(["breadMenus", "userInfo"]),
      buttons() {
        const add = { label: "新增告警规则", handle: this.handleAdd };
        const manage = { label: "告警码管理", handle: this.handleGoAlarmCode };
        return [add, manage];
      },
      searchColumn() {
        const dict = this.dicts;
        const deviceType = {
          prop: "deviceType",
          placeholder: "设备类型",
          tag: "el-select",
          cls: dict.DEVICE_TYPE,
          // required: true,
        };
        const deviceCode = {
          prop: "deviceNameOrCode",
          placeholder: "设备名称或编号",
          showWordLimit: true,
          // tag: "el-select",
          // cls: dict.DEVICE_NAMES,
        };
        const deviceStatus = {
          prop: "deviceStatus",
          placeholder: "设备状态",
          tag: "el-select",
          cls: dict.DEVICES_STATUS,
        };
        return [deviceType, deviceCode, deviceStatus];
      },

    tableColumn() {
      const dict = this.dicts;
      const level = {
        prop: "configLevel",
        label: "配置级别",
        width: 100,
        dict: dict.CONFIG_LEVEL,
      };
      const type = {
        prop: "deviceType",
        label: "设备类型",
        dict: dict.DEVICE_TYPE,
      };
      const name = {
        prop: "deviceCode",
        label: "设备名称(编号)",
        width: "120",
        // dict: dict.DEVICE_NAMES,
        valueType: "{deviceName}({deviceCode})",
      };
      const alarmType = {
        prop: "alarmType",
        label: "告警类型",
        dict: dict.ALARM_TYPE,
      };
      const pointName = {
        prop: "nodeCn",
        label: "点位名称",
        dict: dict.DEVICE_POINTS,
      };
      const deviceStatus = {
        prop: "deviceStatus",
        label: "设备状态",
        dict: dict.DEVICES_STATUS,
      };
      const min = { prop: "thresholdMin", label: "下限" };
      const max = { prop: "thresholdMax", label: "上限" };
      const unit = { prop: "unit", label: "单位" };
      const algFoumula = { prop: "algorithmFormula", label: "规则" };
      const isAlarming = {
        prop: "alarmFlag",
        label: "是否告警",
        dict: dict.ALARM_FLAG,
      };
      const alarmCode = { prop: "alarmCode", label: "告警码" };
      return [
        level,
        type,
        name,
        alarmType,
        // pointName,
        deviceStatus,
        // min,
        // max,
        // unit,
        algFoumula,
        isAlarming,
        alarmCode,
      ];
    },
    tableMenus() {
      const edit = {
        label: "编辑",
        handle: this.handleRowEdit,
        // icon: "el-icon-edit",
      };
      const del = {
        label: "删除",
        handle: this.handleRowDel,
        // icon: "el-icon-delete",
      };
      const addSub = {
        label: "添加子级",
        handle: this.handleRowAddSub,
        // icon: "el-icon-plus",
      };
      return [edit, del, addSub];
    },
    subTableColumn() {
      const dict = this.dicts;
      const ruleType = {
        prop: "ruleType",
        label: "规则类型",
        dict: dict.ALARM_RULE_TYPE,
      };
      const ruleDesc = { prop: "ruleDesc", label: "规则描述" };
      const cycleTime = { prop: "cycleTime", label: "检测周期(秒)" };
      const thresholdStandard = { prop: "thresholdStandard", label: "阈值" };
      const unit = { prop: "unit", label: "单位" };
      const alarmCode = { prop: "alarmCode", label: "告警码" };
      return [ruleType, ruleDesc, cycleTime, thresholdStandard, alarmCode];
    },
    subTableMenus() {
      const edit = {
        label: "编辑",
        handle: this.handleRowEditSub,
        // icon: "el-icon-edit",
      };
      const del = {
        label: "删除",
        handle: this.handleRowDelSub,
        // icon: "el-icon-delete",
      };
      return [edit, del];
    },
    addFormColumn() {
      // 规则
      const { deviceType: dt, configLevel: cl, alarmType: at, alarmFlag: af, nodeArgType: nat, nodeEn: ne, thresholdType: tst } = this.formData;
      const dict = this.dicts;
      const configLevel = {
        prop: "configLevel",
        label: "配置级别",
        elFormItem: { class: "is-block" },
        tag: "el-radio-group",
        value: "2",
        cls: dict.CONFIG_LEVEL.map(({ label, value }) => ({
          label: value,
          cls: label,
        })),
        required: true,
      };
      const deviceType = {
        prop: "deviceType",
        label: "设备类型",
        tag: "el-select",
        cls: dict.DEVICE_TYPE,
        required: true,
      };
      const deviceCode = {
        key: "deviceCode-" + dict.DEVICE_NAMES?.map(d => d.value)?.join("."),
        prop: "deviceCode",
        label: "设备名称",
        tag: "el-select",
        cls: dict.DEVICE_NAMES,
        required: true,
      };
      const alarmType = {
        key: "alarmType-" + dict.ALARM_TYPE?.map(d => d.value)?.join("."),
        prop: "alarmType",
        label: "告警类型",
        tag: "el-radio-group",
        elFormItem: { class: "is-block" },
        value: 1,
        cls: dict.ALARM_TYPE.map(({ label, value }) => ({
          label: value,
          cls: label,
        })),
        required: true,
      };
      // const deviceStatus = {
      //   key: at + "-deviceStatus" + dict.DEVICES_STATUS?.map(d => d.value)?.join("."),
      //   prop: "deviceStatus",
      //   label: "设备状态",
      //   tag: "el-select",
      //   cls: dict.DEVICES_STATUS,
      //   required: at == "3",
      // };
      const deviceStatus = {
        tag: "el-form-item",
        label: "设备状态",
        required: true,
        trigger: "change",
        prop: "deviceStatus",
        cls: {
          tag: 'slot',
          name: "deviceStatus",
        }
      };
      const deviceTypeId = {
        key: at + "-nodeEn" + dict.DEVICE_POINTS?.map(d => d.value)?.join('.'),
        prop: "nodeEn",
        label: "参数选择",
        tag: "el-select",
        multiple: at == "2",
        collapseTags: true,
        cls: dict.DEVICE_POINTS,
        // required:true,
        rules: [{ required: true, trigger: 'blur', message: "请输入 [ 参数选择 ]" }]
      };
      const argValue = {
        prop: "argValue",
        label: "参数值",
        required: true,
        attrs: { maxlength: 200 },
      };
      const alarmFlag = {
        prop: "alarmFlag",
        label: "是否告警",
        tag: "el-radio-group",
        value: false,
        cls: [
          { label: true, cls: "是" },
          { label: false, cls: "否" },
        ],
        // required: true,
      };
      const alarmCode = {
        key: "alarmCode-" + dict.ALARM_CODE?.map(d => d.value)?.join("."),
        prop: "alarmCode",
        label: "告警码",
        tag: "el-select",
        cls: dict.ALARM_CODE,
        filterable: true,
        // remote: true,
        // remoteMethod: (v) => {
        //   const params = v ? { alarmCode: v } : {};
        //   this.getAlarmCodes(params);
        // },
        required: true,
      };
      const thresholdMin = {
        tag: "el-input-number",
        controls: false,
        prop: "thresholdMin",
        label: "下限",
        required: true,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      };
      const thresholdMax = {
        tag: "el-input-number",
        controls: false,
        prop: "thresholdMax",
        label: "上限",
        required: true,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      };
      const argValueType = {
        key: at + '-' + ne?.length ?? 0 + "-nodeArgType",
        tag: "el-radio-group",
        prop: "nodeArgType",
        label: "参数值类型",
        // elFormItem: { class: "is-block" },
        disabled: at == "2" && ne?.length > 1,
        value: 1,
        cls: [{ label: 1, cls: "原始值" }, { label: 2, cls: "计算值" }]
      }
      const algFoumula = {
        key: ne?.length + "-algorithmFormula",
        prop: "algorithmExpression",
        label: "表达式",
        attrs: { maxlength: 200 },
        showWordLimit: true,
        required: true
      }
      const argValueA = {
        key: tst + "-argValueA",
        tag: "el-input-number",
        controls: false,
        prop: "argValueA",
        label: "参数值 A",
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER,
        // showWordLimit: true,
        required: [1, 3, 4, 5].includes(tst)
      }
      const argValueB = {
        key: tst + "-argMaxValue",
        tag: "el-input-number",
        controls: false,
        prop: "argMaxValue",
        label: "参数值 B",
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER,
        // showWordLimit: true,
        required: [2, 3, 4].includes(tst)
      }
      const judgeRule = {
        tag: "el-select",
        prop: "thresholdType",
        label: "判断规则",
        elFormItem: { class: "is-block" },
        rules: [{ required: true, trigger: "change", message: "请选择判断规则！" }],
        cls: [
          { label: "小于 参数值 A", value: 1 },
          { label: "大于 参数值 B", value: 2 },
          { label: "介于 参数值 A 和 参数值 B 之间", value: 3 },
          { label: "小于 参数值 A 或大于 参数值 B", value: 4 },
          { label: "等于 参数值 A", value: 5 },
        ]
      }

        //
        const resMap = new Map([
          ["configLevel", configLevel],
          ["deviceType", deviceType],
          ["deviceCode", deviceCode],
          ["alarmType", alarmType],
          ["deviceStatus", deviceStatus],
          ["deviceTypeId", deviceTypeId],
          ["argValue", argValue],
          // ["thresholdMin", thresholdMin],
          // ["thresholdMax", thresholdMax],

        ["argValueType", argValueType],
        ["algFoumula", algFoumula],
        ["judgeRule", judgeRule],
        ["argValueA", argValueA],
        ["argValueB", argValueB],
        ["alarmFlag", alarmFlag],
        ["alarmCode", alarmCode],
      ]);
      const extKeys = [];
      if (cl == "1") {
        extKeys.push("deviceCode");
      }
      if (at == "1") {
        extKeys.push("argValueType", "algFoumula", "judgeRule", "argValueA", "argValueB");
      } else if (at == "2") {
        extKeys.push("argValue");
        if (ne && ne.length > 1) {
          this.formData.nodeArgType = 2;
        }
      } else if (at == "3") {
        extKeys.push(
          "deviceTypeId",
          "argValue",
          "alarmFlag",
          "alarmCode",
          "argValueType", "algFoumula", "judgeRule", "argValueA", "argValueB"
        );
      }
      if (nat == 1 && (typeof ne == 'string' || ne?.length <= 1)) {
        extKeys.push("algFoumula");
      }
      if (af == false) {
        extKeys.push("alarmCode");
      }
      //
      const res = [];
      for (let k of resMap.keys()) {
        if (!extKeys.includes(k)) {
          res.push(resMap.get(k));
        }
      }
      return res;
    },
    subFormColumn() {
      const dict = this.dicts;
      const deviceName = {
        prop: "deviceCode",
        label: "设备名称",
        tag: "el-select",
        cls: dict.DEVICE_NAMES,
        disabled: true,
      };
      const deviceType = {
        prop: "deviceType",
        label: "设备类型",
        tag: "el-select",
        cls: dict.DEVICE_TYPE,
        disabled: true,
      };
      //
      const ruleType = {
        prop: "ruleType",
        label: "规则类型",
        tag: "el-radio-group",
        elFormItem: { class: "is-block" },
        value: "1",
        cls: dict.ALARM_RULE_TYPE.map(({ label, value }) => ({
          label: value,
          cls: label,
        })),
      };
      const ruleDesc = {
        prop: "ruleDesc",
        label: "规则描述",
        type: "textarea",
        attrs: { maxlength: 200 },
        showWordLimit: true,
        value: "",
        resize: "none",
        rows: 5,
        autosize: { minRows: 4, maxRows: 5 },
        elFormItem: { class: "is-block" },
      };
      const stand = {
        prop: "thresholdStandard",
        label: "阈值标准",
        // attrs: { maxlength: 30 },
        showWordLimit: true,
        rules: [
          { pattern: /^\d+(\.\d+)?$/, message: "请输入浮点值！", trigger: "blur" },
        ],
        required: true,
      };
      const week = {
        tag: "el-input-number",
        controls: false,
        precision: 0,
        prop: "cycleTime",
        label: "检测周期(秒)",
        required: true,
      };
      const alarmCode = {
        prop: "alarmCode",
        label: "告警码",
        elFormItem: { class: "is-block", style: "width:50%;" },
        tag: "el-select",
        cls: dict.ALARM_CODE,
        filterable: true,
        required: true,
      };
      const { ruleType: rt } = this.formData;
      const hasWeek = rt ? ["2", "3"].includes(rt) : false;
      const res = [deviceName, deviceType, ruleType, ruleDesc, stand];
      if (hasWeek) {
        res.push(week);
      }
      res.push(alarmCode);
      return res;
    },
    formColumn() {
      if (["add", "edit"].includes(this.formName)) {
        return this.addFormColumn;
      } else {
        return this.subFormColumn;
      }
    },
  },
  methods: {
    async getList(params) {
      try {
        const res = await getList(params);
        return res.result;
      } catch (e) {
        console.error(e);
      }
      return { total: 0, data: [] };
    },
    async addData(data) {
      try {
        const res = await addData(data);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async updateData(data) {
      try {
        const res = await updateData(data);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async detailData(id) {
      try {
        const res = await detailData(id);
        return res.result;
      } catch (e) {
        console.error(e);
      }
    },
    async delData(id) {
      try {
        const res = await delData(id);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async addChild(data) {
      try {
        const res = await addChild(data);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async updateChild(data) {
      try {
        const res = await updateChild(data);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async delChild(id, sid) {
      try {
        const res = await delChild(id, sid);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async getDeviceStatusDict() {
      const res = await getStateDict();
      this.dicts.DEVICES_STATUS = res.result.data.map((item) => ({
        value: item.value,
        label: item.name,
        fatherNode: item.fatherNode,
        id: item.id
      }));
    },
    async getAlarmTypeDict() {
      const res = await getDict("alarmType");
      this.dicts.ALARM_TYPE = res.map((item) => ({
        value: item.value,
        label: item.name,
      }));
    },
    async getAlarmGradeDict() {
      const res = await getDict("alarmGrade");
      this.dicts.ALARM_GRADE = res.map((item) => ({
        value: item.value,
        label: item.name,
      }));
    },
    async getAlarmRuleTypeDict() {
      const res = await getDict("ruleType");
      this.dicts.ALARM_RULE_TYPE = res.map((item) => ({
        value: item.value.toString(),
        label: item.name,
      }));
    },
    async getDeviceType() {
      const res = await getDeviceTypeData();
      this.dicts.DEVICE_TYPE = res.map((item) => ({
        value: item.id.id,
        label: item.deviceTypeName,
      }));
    },
    async getDeviceNameData(v) {
      const res = await getDeviceNames({ deviceTypeId: v });
      this.dicts.DEVICE_NAMES = res.result.map((item) => ({
        value: item.code,
        label: item.name,
      }));
    },
    async getDevicePointData(v) {
      const res = await getDeviceNodes({ deviceTypeId: v });
      this.dicts.DEVICE_POINTS = res.result.map((item) => ({
        value: item.nameEn,
        label: `${item.nameCn}(${item.nameEn})`,
      }));
    },
    async getAlarmCodes(deviceType, alarmType) {
      this.dicts.ALARM_CODE = [];
      let cusAlarmType = alarmType;
      if (alarmType == "2") {
        cusAlarmType = "2,3,4";
      } else if (alarmType == "3") {
        cusAlarmType = "5";
      }
      const res = await getAlarmCodes(deviceType, cusAlarmType);
      if (!Array.isArray(res.result)) {
        this.dicts.ALARM_CODE = [];
      } else {
        this.dicts.ALARM_CODE = res.result.map((item) => ({
          value: item,
          label: item,
        }));
      }
    },
    async onload(page) {
      const params = { ...this.searchValue, ...page };
      if (params.deviceStatus) {
        params.deviceStatus = (params.deviceStatus.length && params.deviceStatus.length > 0) ? params.deviceStatus[params.deviceStatus.length - 1] : null
      }
      const { total, data } = await this.getList(params);
      // const list = data.map((d) => ({ ...d, deviceType: d.deviceTypeId.id,alarmType:d.alarmType.replace("alarmType_","") }));
      const list = data.map((d) => ({ ...d, deviceType: d.deviceTypeId.id }));
      return { total, list };
    },
    changeDeviceType(v, alarmType) {
      this.formData.deviceCode = "";
      this.formData.deviceTypeId = "";
      if (this.formData.nodeEn) {
        this.formData.nodeEn = "";
      }
      if (this.formData.alarmCode) {
        this.formData.alarmCode = "";
      }
      this.getDeviceNameData(v);
      this.getDevicePointData(v);
      alarmType = alarmType == "deviceType" ? this.formData.alarmType : alarmType;
      this.getAlarmCodes(v, alarmType);
    },
    // 告警类型改变时
    changeAlarmType(v) {
      if (this.formData.alarmCode) {
        this.formData.alarmCode = "";
      }
      if (this.formData.deviceType) {
        this.getAlarmCodes(this.formData.deviceType, v);
      }
    },
    refreshTable() {
      if (this.$refs.elsTableRef) {
        this.$refs.elsTableRef.apiOnload();
      }
    },
    handleSearch() {
      this.refreshTable();
    },
    handleReset() {
      this.searchValue = {};
      this.refreshTable();
    },
    handleAdd() {
      this.formName = "add";
      this.formData = {};
      this.dialogTitle = "新增设备告警规则";
      this.dialogVisible = true;
      this.dicts.ALARM_CODE = [];
    },
    handleGoAlarmCode() {
      this.$router.push({ name: "deviceAlarmCodeManage" });
    },
    async handleRowEdit({ params }) {
      const id = params.id;
      const row = await this.detailData(id)
      // row.alarmType = row.alarmType?.replace("alarmType_","")
      this.formName = "edit";
      const { nodeEn, argValue, alarmType, deviceTypeId = {}, deviceStatus } = row;
      this.changeDeviceType(deviceTypeId.id, row.alarmType);
      const data = { ...row, argValueA: argValue, deviceType: deviceTypeId.id };
      if (alarmType == '2' && nodeEn) {
        data.nodeEn = nodeEn.split(',')
      }
      if (deviceStatus) {
        const status = this.dicts.DEVICES_STATUS.find((item) => item.value == deviceStatus);
        if (status.fatherNode) {
          const statusparent = this.dicts.DEVICES_STATUS.find((item) => item.id == status.fatherNode);
          data.deviceStatus = [statusparent.value, deviceStatus]; 
        } else {
              data.deviceStatus = [deviceStatus]
        }
      }
      //  this.getAlarmCodes(this.formData.deviceType,this.formData.alarmType);
      this.formData = data;
      this.dialogTitle = "编辑设备告警规则";
      this.dialogVisible = true;
    },
    handleRowDel({ params: row }) {
      const id = row.id;
      this.$confirm("确认是否删除这条记录?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.delData(id);
          if (res == undefined) {
            return;
          }
          this.$message.success(res?.msg);
          this.refreshTable();
        })
        .catch(() => { });
    },
    handleRowAddSub({ params: row }) {
      const { id, deviceCode, deviceType } = row;
      this.getAlarmCodes(row.deviceType, row.alarmType);
      this.formName = "add-child";
      this.formData = { id, deviceCode, deviceType };
      this.dialogTitle = "新增设备告警子级";
      this.dialogVisible = true;
    },
    handleRowEditSub({ params }) {
      const { row, subRow } = params;
      const { id, deviceCode, deviceType } = row;
      this.getAlarmCodes(row.deviceType, row.alarmType);
      this.formName = "edit-child";
      this.formData = { ...subRow, id, deviceCode, deviceType };
      this.dialogTitle = "编辑设备告警子级";
      this.dialogVisible = true;
    },
    handleRowDelSub({ params }) {
      const { row, subRow } = params;
      const id = row.id;
      const sid = subRow.sid;
      this.$confirm("确认是否删除这条记录?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.delChild(id, sid);
          if (res == undefined) {
            return;
          }
          this.$message.success(res?.msg);
          this.refreshTable();
        })
        .catch(() => { });
    },
    async handleSubmit() {
      // 校验
      try {
        await this.$refs.elsFormRef.validate();
      } catch (e) {
        return;
      }
      this.submitLoading = true;
      // 调用接口
      // const { deviceType,argValueA, nodeEn,alarmType,...data } = this.formData;
      const { deviceType, argValueA, nodeEn, ...data } = this.formData;
      if (deviceType) {
        // const [tid, tn] = deviceTypeId.split("---");
        data.deviceTypeId = { id: deviceType, entityType: "DEVICE_TYPE" };
        // data.nodeEn = tn;
      }
      if (argValueA || argValueA == 0) {
        data.argValue = argValueA
      }
      if (Array.isArray(nodeEn)) {
        data.nodeEn = nodeEn.join(",")
      }
      else {
        data.nodeEn = nodeEn;
      }
      if (data.deviceStatus) {
        data.deviceStatus = (data.deviceStatus.length && data.deviceStatus.length > 0) ? data.deviceStatus[data.deviceStatus.length - 1] : null
      }
      // if(alarmType && !/^alarmType_/.test(alarmType)){
      //   data.alarmType = "alarmType_" + alarmType
      // }
      let f = this.addData;
      switch (this.formName) {
        case "add":
          f = this.addData;
          // sm = "新增设备报警规则成功！";
          break;
        case "edit":
          f = this.updateData;
          // sm = "编辑设备报警规则成功";
          break;
        case "add-child":
          f = this.addChild;
          // sm = "新增设备报警子级成功";
          break;
        case "edit-child":
          f = this.updateChild;
          // sm = "修改设备报警子级成功";
          break;
      }
      const res = await f(data);
      this.submitLoading = false;
      if (res == undefined) {
        return;
      }
      this.$message.success(res?.msg);
      this.refreshTable();
      this.dialogVisible = false;
    },
    // 降序
    sortId(a, b) {
      return b.sid - a.sid;
    },
    reverseSonRule(sonRule) {
      const rule = JSON.parse(sonRule);
      return rule.sort(this.sortId);
    },
    // 树状查询设备状态字典
    async getDeviceStatusTree() {
      const res = await getStateList();
      this.deviceStatusTree = res.result;
    },
    // 搜索状态条件改变事件
    searchStatusChange() {
      this.$refs.searchCascader.toggleDropDownVisible()
    },
    // 表单状态条件改变事件
    changeFormCascader() {
      this.$refs.formCascader.toggleDropDownVisible()
    }
  },
  created() {
    this.getDeviceStatusDict();
    this.getAlarmTypeDict();
    this.getAlarmGradeDict();
    this.getAlarmRuleTypeDict();
    this.getDeviceType();
    this.getDeviceStatusTree();
  },
};
</script>

<style lang="scss">
.device-alarm-rule-config {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8px;

  ._header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._search {
      display: flex;
      align-items: center;

      .el-form-item {
        margin: 0 4px;
      }
    }
  }

  ._content {
    position: relative;
    width: 100%;
    height: calc(100% - 44px - 44px);
  }

  ._subtable-content {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    // padding: 0 12px;
    padding-left: 48px;
    padding-right: 12px;
  }
}

._dialog-form {
  .el-form {
    >div {
      margin-right: 0;
      width: 50%;
      display: inline-block;
      padding: 0 16px;
      box-sizing: border-box;

      &.is-block {
        display: block;
        width: 100%;
      }

      .el-select {
        width: 100%;
      }

      .el-input-number {
        &.is-without-controls {
          width: 100%;

          input {
            text-align: left;
          }
        }
      }
    }
    ._content {
      position: relative;
      width: 100%;
      height: calc(100% - 44px - 44px);
    }
    ._subtable-content {
      width: 100%;
      position: relative;
      box-sizing: border-box;
      // padding: 0 12px;
      padding-left: 48px;
      padding-right: 12px;
    }
  }

  ._dialog-form {
    .el-form {
      > div {
        margin-right: 0;
        width: 50%;
        display: inline-block;
        padding: 0 16px;
        box-sizing: border-box;
        &.is-block {
          display: block;
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-input-number{
          &.is-without-controls{
            width:100%;
            input{
              text-align: left;
            }
          }
        }

      }
    }
  }
}

</style>
<style scoped lang="scss">
::v-deep .els-button-group .el-button--text:nth-child(3) {
        color: red;
    }
</style>
